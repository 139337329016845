import get from "lodash/get";
import { parsePhoneNumberFromString, CountryCode } from "libphonenumber-js";

import CountryService from "./country-service/country.service";

export const extractNumber = (phoneNumber: string) => {
  try {
    const parsedPhone = parsePhoneNumberFromString(phoneNumber);
    const country = get(parsedPhone, "country");
    const prefix = CountryService.getPhonePrefix(country).replace(/\s+/g, ``);
    return { prefix, phone: phoneNumber.replace(prefix, ""), country };
  } catch {
    return { phone: "" };
  }
};

const checkValidPhonenumber = (value: string, country?: string): boolean => {
  const phoneNumber = parsePhoneNumberFromString(
    value || "",
    country as CountryCode,
  );
  return !!phoneNumber && phoneNumber.isValid();
};

const joinPhoneNumber = (phoneCode: string, phone: string): string =>
  `${CountryService.getPhonePrefix(phoneCode)}${phone}`;

const checkInternationalValidPhonenumber = (value: string, country: string) => {
  const fullNumber = joinPhoneNumber(country, value);
  const phoneNumber = parsePhoneNumberFromString(
    fullNumber,
    country as CountryCode,
  );
  if (phoneNumber && phoneNumber.isValid()) {
    return (
      phoneNumber.formatInternational().replace(/\s+/g, "") ===
      fullNumber.replace(/\s+/g, "")
    );
  }
  return false;
};

export default {
  extractNumber,
  joinPhoneNumber,
  checkValidPhonenumber,
  checkInternationalValidPhonenumber,
};
