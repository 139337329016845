import * as React from "react";
import classnames from "classnames";
import { get } from "lodash";

import { IComponent } from "../component.types";
import { getClassByAttrName } from "../component.utils";

const files = require.context(
  "!svg-sprite-loader!svgo-loader!./icons",
  true,
  /\.svg$/,
);

interface IIconInlineStyles {
  [key: string]: {
    height: string;
  };
}
const iconsInlineStyles: IIconInlineStyles = {};

files.keys().forEach(filePath => {
  const fileData = files(filePath).default;
  const id = fileData.id;
  const [, , width, height] = fileData.viewBox.split(" ");
  iconsInlineStyles[id] = {
    height: `${height / width}em`,
  };
});

const iconAttrClassList = {};

export interface IIconProps
  extends IComponent<typeof iconAttrClassList>,
    React.SVGProps<SVGSVGElement> {
  iconName: string;
  onClick?: () => void;
}

export type IconType = React.FunctionComponent<IIconProps>;

const Icon: IconType = ({
  iconName,
  children,
  mods,
  width,
  height,
  onClick,
  ...props
}) => {
  const iconModsClasses = getClassByAttrName(iconAttrClassList, mods);
  return (
    <svg
      {...props}
      onClick={onClick}
      className={classnames("icon", iconModsClasses, props.className)}
      style={{ display: "inline-block" }}
      width={width || "1em"}
      height={height || get(iconsInlineStyles[iconName], "height", 0)}
      role={props.role}
      aria-hidden={!props.role}
    >
      <use xlinkHref={`#${iconName || children}`} />
    </svg>
  );
};

export default Icon;
