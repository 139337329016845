import axios from "axios";
import CryptoJS from "crypto-js";

import globalConfig from "../../../environment";
import { TOKEN_KEY } from "../constants";

export const baseURL = globalConfig.apiBase;

export const api = axios.create({
  baseURL,
});

api.interceptors.request.use(
  config => {
    if (
      config.url &&
      !config.url.includes("/user/getDocument?id") &&
      !config.url.includes("/user/image/upload")
    ) {
      config.responseType = "json";
    }
    const token = localStorage[TOKEN_KEY];
    const time = new Date().toISOString();
    config.headers.Authorization = `Bearer ${token}`;
    config.headers["x-security-header"] = CryptoJS.AES.encrypt(
      `${globalConfig.password}${time}`,
      CryptoJS.enc.Base64.parse(globalConfig.secret),
      {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      },
    ).toString();
    config.headers["x-time-header"] = time;
    return config;
  },
  error => {
    console.log(error);
    return Promise.reject(error);
  },
);
api.interceptors.response.use(
  resp => {
    return resp;
  },
  error => {
    console.log(error);
    if (!error.response) {
      return Promise.reject(null);
    }
    return Promise.reject({ ...error.response });
  },
);
