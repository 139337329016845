import classnames from "classnames";
import React from "react";

import { IComponent } from "../component.types";
import { getClassByAttrName } from "../component.utils";
import Icon from "../Icon";

import "./index.css";

export const toastAttrClassList = {
  success: "toast--success",
  primary: "toast--primary",
  warning: "toast--warning",
  error: "toast--error",
  info: "toast--info",
};

export interface IToastProps extends IComponent<typeof toastAttrClassList> {
  title?: string;
  message?: string | JSX.Element;
  closeToast?: () => void;
  autoClose?: number;
}

const Toast = ({ title, message, closeToast, mods }: IToastProps) => {
  const toastModsClasses = getClassByAttrName(toastAttrClassList, mods);
  return (
    <div className={classnames("toast", "no-print", toastModsClasses)}>
      <Icon iconName={mods ? mods[0] : ""} className="toast__icon" width={45} />
      <div className="toast__body">
        <h3 className="toast__title">{title}</h3>
        {message && <p className="toast__message">{message}</p>}
      </div>
      <div className="toast__close-button" onClick={closeToast}>
        <Icon iconName="close" />
      </div>
    </div>
  );
};

export default Toast;
