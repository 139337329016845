export const REQUEST = "REQUEST";
export const SUCCESS = "SUCCESS";
export const FAILURE = "FAILURE";
export const CANCEL = "CANCEL";
export const LIST = "LIST";
export const CREATE = "CREATE";
export const UPDATE = "UPDATE";
export const REMOVE = "REMOVE";
export const ONE = "ONE";
export const CLEAR_FAILURE_ERROR = "CLEAR_FAILURE_ERROR";

export interface IGeneratedConstants {
  [REQUEST]: string;
  [SUCCESS]: string;
  [FAILURE]: string;
  [CANCEL]: string;
  [CLEAR_FAILURE_ERROR]: string;
}

export interface IGeneratedCrudConstants {
  [LIST]: IGeneratedConstants;
  [ONE]: IGeneratedConstants;
  [CREATE]: IGeneratedConstants;
  [UPDATE]: IGeneratedConstants;
  [REMOVE]: IGeneratedConstants;
}
