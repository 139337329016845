import { createSelector } from "reselect";
import { get } from "lodash";

import { IAppState } from "../../types";

import { RecipientStoreConstants } from "./recipient.constants";

const { RECIPIENTS_LIST } = RecipientStoreConstants;

export const recipientsListSelector = ({ recipients }: IAppState) =>
  get(recipients, RECIPIENTS_LIST, []);

export const getRecipientSelectorById = (id: string) =>
  createSelector(
    recipientsListSelector,
    recipientsList =>
      recipientsList.find(({ recipientId }) => recipientId === id),
  );
