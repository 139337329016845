import { createStore, applyMiddleware, Store } from "redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import { createLogger } from "redux-logger";
import { createBrowserHistory, BrowserHistoryBuildOptions } from "history";
import { routerMiddleware } from "connected-react-router";
import * as Sentry from "@sentry/browser";

import createRootReducer from "./core/reducer";
import rootSaga from "./core/saga";
import { TOKEN_KEY } from "./core/constants";

const options: BrowserHistoryBuildOptions = {};
if (process.env.REACT_APP_BASENAME) {
  options.basename = process.env.REACT_APP_BASENAME;
}

export const history = createBrowserHistory(options);

const sagaMiddleware = createSagaMiddleware({
  onError(error) {
    Sentry.captureException(error);
    throw error;
  },
});

const logger = createLogger({
  collapsed: true,
  duration: true,
  diff: true,
});

const middlewares = [sagaMiddleware, routerMiddleware(history)];

if (process.env.NODE_ENV !== "production") {
  middlewares.push(logger);
}

const configureStore = () => {
  const configuredStore: Store = createStore(
    createRootReducer(history),
    {
      user: {
        token: localStorage.getItem(TOKEN_KEY),
      },
    },
    composeWithDevTools(applyMiddleware(...middlewares)),
  );

  if (process.env.NODE_ENV !== "production") {
    if (module.hot) {
      module.hot.accept("./core/reducer.ts", () => {
        configuredStore.replaceReducer(createRootReducer(history));
      });
    }
  }

  return configuredStore;
};

const store = configureStore();

sagaMiddleware.run(rootSaga);

store.subscribe(() => {
  localStorage.setItem(TOKEN_KEY, store.getState().user.token || "");
});

export default store;
