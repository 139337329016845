import { all, fork, put, takeEvery, select } from "redux-saga/effects";
import { AnyAction } from "redux";

import { requestSagaHandler } from "../../utils/sagas";
import { SUCCESS } from "../../utils/types/create-constants.types";
import globalConfig from "../../../../environment";
import { getUserDataTypes } from "../user/user.constants";
import environment from "../../../../environment";
import { Spinners } from "../ui/ui.constants";

import {
  GET_ORGANIZATION_DATA_ENDPOINT,
  getOrganizationDataTypes,
  getMobileConfigTypes,
  getRegistrationFieldsTypes,
  GET_MOBILE_CONFIG_ENDPOINT,
  GET_REGISTRATION_FIELDS_ENDPOINT,
  GET_RECIPIENT_RELATIONSHIPS_ENDPOINT,
  getRecipientRelationshipsTypes,
  getPurposesOfTransferTypes,
  GET_PURPOSES_OF_TRANSFER_ENDPOINT,
  GET_SOURCE_OF_FUNDS_ENDPOINT,
  getSourcesOfFundsTypes,
  paymentProcessorsSourcesConfig,
  getDocTypes,
  getIdDocTypes,
  GET_DOC_TYPES_ENDPOINT,
  GET_ID_DOC_TYPES_ENDPOINT,
  SETUP_SECURE_TRADING,
  GET_LIST_OF_AGENTS_ENDPOINT,
  getListOfAgentsTypes,
  getUserActionNeededTypes,
  GET_USER_NEEDED_ENDPOINT,
} from "./organization.constants";
import {
  getOrganizationDataActions,
  getMobileConfigActions,
  getRecipientRelationshipsActions,
  getPurposesOfTransferActions,
  getSourcesOfFundsActions,
  getDocTypesActions,
  getIdDocTypesActions,
  getListOfAgentsAction,
  getUserActionNeededActions,
  getRegistrationFieldsActions,
} from "./organization.actions";
import {
  agentCountrySelector,
  paymentProcessorsByCountrySelector,
  paymentProcessorSelector,
  organizationIdSelector,
} from "./organization.selectors";

const bodyGetter = (country?: string) => {
  console.log("[Config]: Fetched with pre-defined country: ", country);

  return {
    data: {
      organizationId: globalConfig.organizationId,
      country: country ? country : globalConfig.country,
    },
  };
};

function* concatUrlWithAgentCountry(url: string) {
  const agentCountry = yield select(agentCountrySelector);

  return (
    agentCountry && {
      url: `${url}/${agentCountry}`,
    }
  );
}

const getOrganizationDataFork = fork(requestSagaHandler.post, {
  actions: getOrganizationDataActions,
  paramsGetter: bodyGetter,
  types: getOrganizationDataTypes,
  url: GET_ORGANIZATION_DATA_ENDPOINT,
  spinner: Spinners.GET_USER_DATA_SPINNER,
});

const getUserActionNeededFork = fork(requestSagaHandler.get, {
  actions: getUserActionNeededActions,
  types: getUserActionNeededTypes,
  url: GET_USER_NEEDED_ENDPOINT,
});

const getMobileConfigurationFork = fork(requestSagaHandler.post, {
  actions: getMobileConfigActions,
  paramsGetter: bodyGetter,
  types: getMobileConfigTypes,
  url: GET_MOBILE_CONFIG_ENDPOINT,
});

const getRegistrationFieldsFork = fork(requestSagaHandler.post, {
  actions: getRegistrationFieldsActions,
  paramsGetter: bodyGetter,
  types: getRegistrationFieldsTypes,
  url: GET_REGISTRATION_FIELDS_ENDPOINT,
});

const getRecipientRelationshipsFork = fork(requestSagaHandler.get, {
  actions: getRecipientRelationshipsActions,
  paramsGetter: () =>
    concatUrlWithAgentCountry(GET_RECIPIENT_RELATIONSHIPS_ENDPOINT),
  types: getRecipientRelationshipsTypes,
});

const getPurposesOfTransferFork = fork(requestSagaHandler.get, {
  actions: getPurposesOfTransferActions,
  paramsGetter: () =>
    concatUrlWithAgentCountry(GET_PURPOSES_OF_TRANSFER_ENDPOINT),
  types: getPurposesOfTransferTypes,
});

const getSourcesOfFundsFork = fork(requestSagaHandler.get, {
  actions: getSourcesOfFundsActions,
  paramsGetter: () => concatUrlWithAgentCountry(GET_SOURCE_OF_FUNDS_ENDPOINT),
  types: getSourcesOfFundsTypes,
});

const getDocTypesFork = fork(requestSagaHandler.get, {
  url: GET_DOC_TYPES_ENDPOINT,
  actions: getDocTypesActions,
  types: getDocTypes,
});

const getIdDocTypesFork = fork(requestSagaHandler.get, {
  url: GET_ID_DOC_TYPES_ENDPOINT,
  actions: getIdDocTypesActions,
  types: getIdDocTypes,
});

function* getUserActionsNeeded() {
  yield all([put(getUserActionNeededActions.request())]);
}

function* getOrganizationExtraOptionsSaga() {
  yield all([
    put(getRecipientRelationshipsActions.request()),
    put(getDocTypesActions.request()),
    put(getIdDocTypesActions.request()),
  ]);
}

function* loadPaymentProcessorsScripts() {
  const paymentProcessorsByCountry = yield select(
    paymentProcessorsByCountrySelector,
  );

  paymentProcessorsByCountry.forEach(({ processor }: any) => {
    const script = document.createElement("script");
    const sources = paymentProcessorsSourcesConfig[processor.name];
    if (!sources) {
      return;
    }
    script.src =
      environment.name === "production" ? sources.live : sources.test;

    document.body.appendChild(script);
  });
  // load srcDoc polyfill for iframes. support EI9
  const polyfillScript = document.createElement("script");
  polyfillScript.src =
    "https://cdn.jsdelivr.net/npm/srcdoc-polyfill@1.0.0/srcdoc-polyfill.min.js";
  polyfillScript.setAttribute("crossorigin", "anonymous");
  document.body.appendChild(polyfillScript);
}

function* setupSecureTrading({
  payload: { submitFormCallback, errorCallback },
}: AnyAction) {
  const { reference } = yield select(paymentProcessorSelector);
  const SecureTrading = (window as any).SecureTrading;
  // tslint:disable-next-line: no-unused-expression
  new SecureTrading.Standard({
    sitereference: reference,
    locale: "en_gb",
    submitFormCallback,
    errorCallback,
  });
}

function* getOrganizationConfigs() {
  yield all([
    put(getOrganizationDataActions.request()),
    put(getMobileConfigActions.request()),
    put(getRegistrationFieldsActions.request()),
  ]);
}

function* concatUrlWithAgentOrganization(url: string) {
  return (
    environment.organizationId && {
      url: `${url}/?organizationId=${environment.organizationId}`,
    }
  );
}

const getListOfAgentsFork = fork(requestSagaHandler.get, {
  actions: getListOfAgentsAction,
  paramsGetter: () =>
    concatUrlWithAgentOrganization(GET_LIST_OF_AGENTS_ENDPOINT),
  types: getListOfAgentsTypes,
});

export default function* organizationSaga() {
  yield all([
    getOrganizationDataFork,
    getMobileConfigurationFork,
    getRegistrationFieldsFork,
    getDocTypesFork,
    getIdDocTypesFork,
    getRecipientRelationshipsFork,
    getPurposesOfTransferFork,
    getSourcesOfFundsFork,
    getListOfAgentsFork,
    getUserActionNeededFork,
    takeEvery(getUserDataTypes[SUCCESS], getOrganizationExtraOptionsSaga),
    takeEvery(getMobileConfigTypes[SUCCESS], loadPaymentProcessorsScripts),
    takeEvery(getMobileConfigTypes[SUCCESS], getUserActionsNeeded),
    takeEvery(SETUP_SECURE_TRADING, setupSecureTrading),
  ]);
  yield getOrganizationConfigs();
}
