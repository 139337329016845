export const TOGGLE_SPINNER = "TOGGLE_SPINNER";
export const TOGGLE_MODAL = "TOGGLE_MODAL";
export const TOGGLE_UNAVAILABLE = "TOGGLE_UNAVAILABLE";
export const SET_DOCUMENT_UPLOAD_REDIRECT_ROUTE =
  "SET_DOCUMENT_UPLOAD_REDIRECT_ROUTE";

export const USER_NEED_EMPTY = "userNeedEmpty";

export enum Spinners {
  CALCULATOR_SPINNER = "calculatorSpinner",
  LOADING_BANKS_SPINNER = "loadingBanksSpinner",
  LOADING_BANK_BRANCHES_SPINNER = "loadingBankBranchesSpinner",
  TRANSACTIONS_SPINNER = "transactionsSpinner",
  TRANSACTION_NEW_SPINNER = "transactionNewSpinner",
  LOADING_MOBILE_OPERATOR_SPINNER = "loadingMobileOperatorSpinner",
  GET_USER_DATA_SPINNER = "getUserDataSpinner",
  GET_USER_DOCUMENTS_SPINNER = "getUserDocumentsSpinner",
  GET_USER_CONTACT_OPTIONS = "userContactOptionsSpinner",
  GET_AUTOCOMPLETE_OPTIONS = "getAutocompleteOptionsSpinner",
  VERIFY_PROMOCODE_SPINNER = "verifyPromocodeSpinner",
  IFSC_SEARCH_SPINNER = "ifscSearchSpinner",
  LOADING_PAYOUT_TYPE_DETAILS_SPINNER = "loadingPayoutTypeDetailsSpinner",
  API_LOADING_SPINNER = "apiLoadingSpinner",
  SIGN_UP_SPINNER = "signupSpinner",
}

export enum Modals {
  MOBILE_CONFIRMATION_MODAL = "mobileConfirmationModal",
  BANK_DETAILS_MODAL = "bankDetailsModal",
  PHONE_VERIFICATION_MODAL = "phoneVerificationModal",
  TRANSACTION_INFO_MODAL = "transactionInfoModal",
  DUPLICATED_TRANSACTION_MODAL = "duplicatedTransactionModal",
  SUPPORT_MODAL = "supportModal",
  PICKUP_LOCATIONS_MODAL = "pickupLocationsModal",
  SKIP_UPLOAD_CONFIRMATION_MODAL = "skipUploadConfirmationModal",
  EMAIL_VERIFICATION_MODAL = "emailVerificationModal",
  NATIONALITY_MODAL = "nationalityModal",
  USER_ALERT_MODAL = "userAlertMessage",
  USER_BLOCKED_MODAL = "userBlockedMessage",
  TWOFA_GENERATE_QR_CODE_MODAL = "twoFAGenerateQRCodeModal",
  TWOFA_DISABLE_MODAL = "twoFADisableModal",
  UPDATE_ID_DATA_MODAL = "updateIdDataModal",
  CUSTOMER_AWARENESS_MODAL = "customerAwarenessModal",
  USER_NEED_ACTIONS = "userNeedActions",
  SETUP_MPIN_MODAL = "setupMpinModal",
}
export const UNAVAILABLE_SERVICE = "unavailableService";
export const REDIRECT_ROUTE_AFTER_DOC_UPLOAD = "redirectRouteAfterDocUpload";
export const USER_NEEDED_DOCUMENTS = "userNeededDocuments";
export const USER_NEEDED_QUESTIONNARIES = "userNeededQuestionnaries";
