import padStart from "lodash-es/padStart";
import capitalize from "lodash-es/capitalize";
import sortBy from "lodash-es/sortBy";

export const parseConstant = (value: string): string => {
  return value
    ? capitalize(
        value
          .toLowerCase()
          .split("_")
          .join(" "),
      )
    : "";
};

export const mapDropdownOptions = (
  data: any[],
  mapFunc?: (param: any) => string,
) => {
  return sortBy(
    data.map((item: string) => ({
      label: mapFunc ? mapFunc(item) : item,
      value: item,
    })),
    "label",
  );
};

export const formatAmount = (input: number | string, decimals: number = 2) => {
  const value = !input || input === "N/A" ? 0 : input;

  return Number(value).toLocaleString("en-US", {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
};

export function formatDateWithLeadingZero(value: string | number): string {
  const preparedValue = `${value}`;

  return padStart(preparedValue, 2, "0");
}

export function formatCardNumber(value: string): string {
  return value.replace(/-/g, " ").replace(/x/g, "*");
}
