import { get } from "lodash";

import { IAppState } from "../../types";

import {
  Modals,
  Spinners,
  UNAVAILABLE_SERVICE,
  REDIRECT_ROUTE_AFTER_DOC_UPLOAD,
} from "./ui.constants";

export const getSpinnerSelector = (spinner: Spinners, initial = false) => ({
  ui,
}: IAppState) => (!(spinner in ui) ? initial : ui[spinner]);

export const getModalSelector = (modal: Modals) => ({ ui }: IAppState) =>
  ui[modal];

export const selectUnavailableStatus = ({ ui }: IAppState) => {
  return ui[UNAVAILABLE_SERVICE];
};

export const selectRedirectRouteAfterDocUpload = ({ ui }: IAppState) => {
  return ui[REDIRECT_ROUTE_AFTER_DOC_UPLOAD];
};

export const selectNeedActions = ({ ui }: IAppState) => {
  return {
    documents: ui.userNeededDocuments,
    questionnary: ui.userNeededQuestionnaries,
  };
};
