import { api } from "../utils/apiClient";

class IntroducerCode {
  DELETE_LOCATION_CODE = "DELETE_LOCATION_CODE";

  updateLocationCode(code: string | null) {
    if (code === null || code === undefined) {
      return;
    }

    if (this.deleteIfNeed(code)) {
      return;
    }

    localStorage.setItem(INTRODUCER_CODE, code);
    sessionStorage.setItem(INTRODUCER_CODE, code);
  }

  deleteIfNeed(code: string) {
    if (code !== this.DELETE_LOCATION_CODE) {
      return false;
    }

    localStorage.removeItem(INTRODUCER_CODE);
    sessionStorage.removeItem(INTRODUCER_CODE);

    return true;
  }

  get introducerCode() {
    const code = sessionStorage.getItem(INTRODUCER_CODE);
    if (code !== null) {
      return code;
    }

    return localStorage.getItem(INTRODUCER_CODE);
  }

  get hasLocationCode() {
    return this.introducerCode !== null;
  }
}

export default new IntroducerCode();
export const INTRODUCER_CODE = "introducerCode";
export const DELETE_LOCATION_CODE = "DELETE_LOCATION_CODE";
