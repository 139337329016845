import { all, fork, select } from "redux-saga/effects";
import isArray from "lodash/isArray";

import { requestSagaHandler } from "../../utils/sagas";

import { currentQuestionarySelector } from "./questionnaire.selectors";
import {
  questionnarieLoadActions,
  questionnarieSaveActions,
} from "./questionnaire.actions";
import {
  questionnarieLoadTypes,
  questionnarieSaveTypes,
  QUESTIONNARIE_LOAD_ENDPOINT,
  QUESTIONNARIE_SAVE_ENDPOINT,
} from "./questionnaire.constants";
import { IQuestionElementType } from "./questionnaire.type";

const questionnarieLoadBodyGetter = (questionnaireId: any) => ({
  data: {
    questionnaireId,
  },
});

function* questionnarieSaveBodyGetter(values: any) {
  const { questionnary } = yield select(currentQuestionarySelector);
  const { questions, questionnaireId, loggedInUserId } = questionnary;

  return {
    data: {
      questionnaireId,
      userId: loggedInUserId,
      answers: questions.map((question: IQuestionElementType) => ({
        questionTitle: question.title,
        answer:
          values[question.id] === "other" &&
          question.answerType === "COMBOBOX_WITH_INPUT"
            ? values[`${question.id}_other`]
            : isArray(values[question.id])
            ? values[question.id].join(", ")
            : values[question.id],
      })),
    },
  };
}

const questionnarieLoadFork = fork(requestSagaHandler.post, {
  actions: questionnarieLoadActions,
  paramsGetter: questionnarieLoadBodyGetter,
  types: questionnarieLoadTypes,
  url: QUESTIONNARIE_LOAD_ENDPOINT,
});

const questionnarieSaveFork = fork(requestSagaHandler.post, {
  actions: questionnarieSaveActions,
  paramsGetter: questionnarieSaveBodyGetter,
  types: questionnarieSaveTypes,
  url: QUESTIONNARIE_SAVE_ENDPOINT,
});

export default function* questionnarySaga() {
  yield all([questionnarieLoadFork, questionnarieSaveFork]);
}
