import React from "react";
import { toast } from "react-toastify";

import Toast, { IToastProps } from "../components/Toast";

export default {
  success: (options: IToastProps) => {
    toast.success(<Toast title="Success" mods={["success"]} {...options} />, {
      autoClose: options.autoClose || 30000,
    });
  },
  error: (options: IToastProps) => {
    toast.error(<Toast title="Error" mods={["error"]} {...options} />, {
      autoClose: options.autoClose || 30000,
    });
  },
  warn: (options: IToastProps) => {
    toast.warn(<Toast title="Warning" mods={["warning"]} {...options} />, {
      autoClose: options.autoClose || 30000,
    });
  },
  info: (options: IToastProps) => {
    toast.info(<Toast title="Information" mods={["info"]} {...options} />, {
      autoClose: options.autoClose || 30000,
    });
  },
  primary: (options: IToastProps) => {
    toast.info(<Toast mods={["primary"]} {...options} />, {
      autoClose: options.autoClose || 30000,
    });
  },
};
