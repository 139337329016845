import {
  IGeneratedActions,
  IGeneratedCrudActions,
} from "./types/action-creator.types";
import {
  FAILURE,
  IGeneratedConstants,
  REQUEST,
  SUCCESS,
  CANCEL,
  IGeneratedCrudConstants,
  LIST,
  CREATE,
  UPDATE,
  REMOVE,
  ONE,
  CLEAR_FAILURE_ERROR,
} from "./types/create-constants.types";

export const createActions = (
  constants: IGeneratedConstants,
): IGeneratedActions => ({
  canceled: <P>(payload?: P) => ({ type: constants[CANCEL], payload }),
  failure: <E>(error?: E) => ({ type: constants[FAILURE], error }),
  request: <P>(payload?: P) => ({ type: constants[REQUEST], payload }),
  success: <P>(payload?: P) => ({ type: constants[SUCCESS], payload }),
  clearFailure: <P>(payload?: P) => ({
    type: constants[CLEAR_FAILURE_ERROR],
    payload,
  }),
});

export const createCrudActions = (
  constants: IGeneratedCrudConstants,
): IGeneratedCrudActions => ({
  create: createActions(constants[CREATE]),
  list: createActions(constants[LIST]),
  one: createActions(constants[ONE]),
  remove: createActions(constants[REMOVE]),
  update: createActions(constants[UPDATE]),
});

export const createSetterAction = (type: string, ...args: string[]) => {
  return (...values: any[]) => ({
    type,
    payload: {
      ...args.reduce((acc, key, i) => ({ ...acc, [key]: values[i] }), {}),
    },
  });
};
