import { createActions } from "../../utils";

import {
  getRecipientsTypes,
  addRecipientTypes,
  editRecipientTypes,
  updateRecipientBankAccountTypes,
} from "./recipient.constants";

export const getRecipientsActions = createActions(getRecipientsTypes);
export const addRecipientActions = createActions(addRecipientTypes);
export const editRecipientActions = createActions(editRecipientTypes);
export const updateRecipientBankAccountActions = createActions(
  updateRecipientBankAccountTypes,
);
