export interface IRecipientState {
  recipientsList: IRecipient[];
}

export interface IRecipientAccount {
  accountNumber: string;
  operatorId: string;
  payoutType: string;
}

export class BankAccount {
  payoutType: string;
  accountNumber: string;
  bankName: string;
  bankCode: string;
  bankBranchCode: string;
  bankBranchName: string;

  accountNumberType?: string;
  bankAddress?: string;
  bankCity?: string;
  bankDistrict?: string;
  bankState?: string;
  ezwitchNumber?: string;
  ibanCode?: string;
  ifscCode?: string;
  sterlingCardNumber?: string;
  swift?: string;

  constructor(payoutType: string) {
    this.payoutType = payoutType;
    this.accountNumber = "";
    this.bankName = "";
    this.bankCode = "";
    this.bankBranchCode = "";
    this.bankBranchName = "";
  }
}

export interface IRecipient {
  accountNumber: string;
  accountNumberType: string;
  address: string;
  bankAddress: string;
  bankBranchCode: string;
  bankBranchName: string;
  bankCity: string;
  bankCode: string;
  bankDistrict: string;
  bankName: string;
  bankState: string;
  city: string;
  country: string;
  dateOfBirth: string;
  ezwitchNumber: string;
  firstName: string;
  gender: string;
  ibanCode: string;
  idNumber: string;
  idType: string;
  ifscCode: string;
  lastName: string;
  loggedInUserId: string;
  middleName: string;
  organizationId: string;
  phoneCountry: string;
  phoneNumber: string;
  recentTransactions: any;
  recipientId: string;
  relationshipToSender: string;
  senderId: string;
  state: string;
  sterlingCardNumber: string;
  swift: string;
  zipCode: string;
  bankAccount: BankAccount | undefined;
  recipientAccounts: IRecipientAccount[];
  bankAccounts: BankAccount[];
  whiteListed: boolean;
}
