import { combineReducers } from "redux";
import { createReducer } from "../../utils";
import { SUCCESS } from "../../utils/types/create-constants.types";
import { questionnarieLoadTypes } from "./questionnaire.constants";
import {
  IQuestionnaryStateType,
  IQuestionnaryType,
} from "./questionnaire.type";

export const getQuestionnaryReducer = createReducer<IQuestionnaryType | null>(
  {
    [questionnarieLoadTypes[SUCCESS]]: (_, { payload }) => payload,
  },
  null,
);

export default combineReducers<IQuestionnaryStateType>({
  questionnary: getQuestionnaryReducer,
});
