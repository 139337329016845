import { get } from "lodash-es";

import {
  PayoutTypesNames,
  fundingSourcesNames,
} from "../modules/transfer/transfer.constants";

import { parseConstant } from "./helpers.service";

const getPayoutTypeName = (payoutTypeConstant: string): string => {
  return get(
    PayoutTypesNames[payoutTypeConstant],
    "name",
    parseConstant(payoutTypeConstant),
  );
};

const getFundingSourceName = (fundingSourceConstant: string): string => {
  if (!fundingSourceConstant) {
    return fundingSourceConstant;
  }
  return (
    fundingSourcesNames[fundingSourceConstant] ||
    parseConstant(fundingSourceConstant)
  );
};

export default {
  getPayoutTypeName,
  getFundingSourceName,
};
