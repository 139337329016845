import React from "react";

export function GreetingToast({ message }: { message: string }) {
  return (
    <div className="toast-content">
      <span className="toast-content--before">
        <img
          style={{ height: 26 }}
          src={`${process.env.PUBLIC_URL}/assets/confetti-icon-29.jpg`}
        />
      </span>
      <span className="toast-content--text">{message}</span>
    </div>
  );
}
