import {
  IGeneratedConstants,
  REQUEST,
  SUCCESS,
  FAILURE,
  CANCEL,
  LIST,
  CREATE,
  UPDATE,
  REMOVE,
  ONE,
  IGeneratedCrudConstants,
  CLEAR_FAILURE_ERROR,
} from "./types/create-constants.types";

const withPrefix = (type: string, prefix: string): string =>
  `${type}_${prefix}`;

export const createConstants = (type: string): IGeneratedConstants => ({
  [REQUEST]: withPrefix(type, REQUEST),
  [SUCCESS]: withPrefix(type, SUCCESS),
  [FAILURE]: withPrefix(type, FAILURE),
  [CANCEL]: withPrefix(type, CANCEL),
  [CLEAR_FAILURE_ERROR]: withPrefix(type, CLEAR_FAILURE_ERROR),
});

export const createCrudConstants = (type: string): IGeneratedCrudConstants => ({
  [ONE]: createConstants(withPrefix(type, ONE)),
  [LIST]: createConstants(withPrefix(type, LIST)),
  [CREATE]: createConstants(withPrefix(type, CREATE)),
  [UPDATE]: createConstants(withPrefix(type, UPDATE)),
  [REMOVE]: createConstants(withPrefix(type, REMOVE)),
});
