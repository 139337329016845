import { eventChannel, END } from "redux-saga";

export default function countdown(secs: number) {
  let counter = 0;
  return eventChannel(emitter => {
    const iv = setInterval(() => {
      counter += 1;
      if (counter < secs) {
        emitter(secs);
      } else {
        // this causes the channel to close
        emitter(END);
      }
    }, 1000);
    // The subscriber must return an unsubscribe function
    return () => {
      clearInterval(iv);
    };
  });
}
