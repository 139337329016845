import { all, fork, put, select, takeEvery } from "redux-saga/effects";

import { requestSagaHandler } from "../../utils/sagas";
import {
  userLoginTypes,
  userSignupLastStepTypes,
  getUserDataTypes,
} from "../user/user.constants";
import { SUCCESS } from "../../utils/types/create-constants.types";
import { HISTORY_GO_BACK, API_MESSAGE } from "../../constants";
import environment from "../../../../environment";
import { userDataSelector } from "../user/user.selectors";
import { Spinners } from "../ui/ui.constants";

import {
  getRecipientsActions,
  addRecipientActions,
  editRecipientActions,
  updateRecipientBankAccountActions,
} from "./recipient.actions";
import {
  addRecipientTypes,
  editRecipientTypes,
  updateRecipientBankAccountTypes,
} from "./recipient.constants";
import { IRecipient, BankAccount } from "./recipient.types";
import {
  getRecipientsTypes,
  GET_RECIPIENTS_ENDPOINT,
  ADD_NEW_RECIPIENT_ENDPOINT,
  EDIT_RECIPIENT_ENDPOINT,
} from "./recipient.constants";

function* bodyGetter() {
  const { userId: senderId } = yield select(userDataSelector);
  return { data: { organizationId: environment.organizationId, senderId } };
}

function* updateRecipientBankAccountBodyGetter(payload: {
  bankAccount: BankAccount;
  recipient: IRecipient;
}) {
  const { userId: senderId } = yield select(userDataSelector);
  const data = {
    ...payload.recipient,
    senderId,
    organizationId: environment.organizationId,
    bankAccount: payload.bankAccount,
  };

  return { data };
}

function* addRecipientBodyGetter(recipient: IRecipient) {
  const { userId: senderId } = yield select(userDataSelector);
  const data = {
    ...recipient,
    senderId,
    organizationId: environment.organizationId,
  };

  return { data };
}

function* getRecipientsSaga() {
  yield put(getRecipientsActions.request());
}

const getRecipientsListFork = fork(requestSagaHandler.post, {
  actions: getRecipientsActions,
  paramsGetter: bodyGetter,
  types: getRecipientsTypes,
  url: GET_RECIPIENTS_ENDPOINT,
  spinner: Spinners.API_LOADING_SPINNER,
});

const addNewRecipientFork = fork(requestSagaHandler.post, {
  actions: addRecipientActions,
  paramsGetter: addRecipientBodyGetter,
  types: addRecipientTypes,
  url: ADD_NEW_RECIPIENT_ENDPOINT,
  navigate: {
    SUCCESS: HISTORY_GO_BACK,
  },
  toastMessage: {
    SUCCESS: "Recipient created successfully",
    FAILURE: API_MESSAGE,
  },
});

const editRecipientFork = fork(requestSagaHandler.post, {
  actions: editRecipientActions,
  paramsGetter: addRecipientBodyGetter,
  types: editRecipientTypes,
  url: EDIT_RECIPIENT_ENDPOINT,
  toastMessage: {
    SUCCESS: "recipient edited successfully",
    FAILURE: API_MESSAGE,
  },
});

const updateRecipientBankAccountFork = fork(requestSagaHandler.post, {
  actions: updateRecipientBankAccountActions,
  paramsGetter: updateRecipientBankAccountBodyGetter,
  types: updateRecipientBankAccountTypes,
  url: EDIT_RECIPIENT_ENDPOINT,
  navigate: {
    FAILURE: HISTORY_GO_BACK,
  },
  toastMessage: {
    FAILURE: "failed to update recipient bank account",
  },
});

export default function* recipientSaga() {
  yield all([
    getRecipientsListFork,
    addNewRecipientFork,
    editRecipientFork,
    updateRecipientBankAccountFork,
    takeEvery(
      [userSignupLastStepTypes[SUCCESS], getUserDataTypes[SUCCESS]],
      getRecipientsSaga,
    ),
    takeEvery(addRecipientTypes[SUCCESS], getRecipientsSaga),
  ]);
}
