import { createConstants } from "../../utils";

const QUESTIONNARIE_LOAD = "QUESTIONNARIE_LOAD";
const QUESTIONNARIE_SAVE = "QUESTIONNARIE_SAVE";

export const questionnarieLoadTypes = createConstants(QUESTIONNARIE_LOAD);
export const questionnarieSaveTypes = createConstants(QUESTIONNARIE_SAVE);

// endpoints
export const QUESTIONNARIE_LOAD_ENDPOINT = "/questionnaire/questions";
export const QUESTIONNARIE_SAVE_ENDPOINT =
  "/questionnaire/saveUserQuestionnaire";
