import get from "lodash/get";
import sortBy from "lodash-es/sortBy";

import { CountryData } from "./countryData";

const getCountryOptions = (countries: [], selector: any, toCounty?: boolean) =>
  countries.map(country =>
    toCounty
      ? {
          label: country,
          value: selector ? selector(country) : country,
        }
      : {
          label: selector ? selector(country) : country,
          value: country,
        },
  );

interface ICountryData {
  phonePrefix: string;
  fullName: string;
  currencySign: string;
  currency: string;
  countryCode: string;
  flagClass: string;
  utcOffset: string;
  latitude: string;
  longitude: string;
}

class CountryService {
  static getPhonePrefix(countryCode: string | undefined) {
    if (!countryCode) {
      return "";
    }

    if (!CountryData[countryCode]) {
      window.console.warn(
        `Can't find phone prefix for ${countryCode}, return ""`,
      );
      return "";
    }

    return CountryData[countryCode].phonePrefix;
  }

  static getFullName(countryCode: string) {
    if (!countryCode) {
      return "";
    }

    return get(CountryData[countryCode], "fullName");
  }

  static getCurrencySymbol(countryCode: string) {
    if (!countryCode) {
      return "";
    }

    return CountryData[countryCode].currencySign;
  }

  static getCurrencyCode(countryCode: string) {
    if (!countryCode) {
      return "";
    }

    return CountryData[countryCode].currency;
  }

  static getCountryCode(countryCode: string) {
    if (!countryCode) {
      return "";
    }

    return CountryData[countryCode].countryCode;
  }

  static getFlagClass(countryCode: string) {
    if (!countryCode) {
      return "";
    }

    return CountryData[countryCode].flagClass;
  }

  static getCountryCodeByCurrency(currencyCode: string) {
    if (!currencyCode) {
      return "";
    }
    const countryObj = Object.values(CountryData).find(
      country => country.currency === currencyCode,
    );
    return countryObj ? countryObj.countryCode : "";
  }

  static getFlagByCurrency(currencyCode: string) {
    if (!currencyCode) {
      return "";
    }
    const countryObj = Object.values(CountryData).find(
      country => country.currency === currencyCode,
    );
    return currencyCode === "EUR" ? "EU" : countryObj.countryCode;
  }

  static getAllCountries() {
    return Object.keys(CountryData)
      .map(country => this.getFullName(country))
      .sort()
      .map(fullName => this.getCountryCodeByFullName(fullName));
  }

  static getAllCountriesData(): ICountryData[] {
    return sortBy(Object.values(CountryData), "fullName");
  }

  static getCountriesDataByCode(countryCode: string): ICountryData {
    return CountryData[countryCode];
  }

  static get phonePrefixCountries() {
    return getCountryOptions(
      this.getAllCountries() as [],
      CountryService.getPhonePrefix,
    );
  }

  static getLanguageByLanguageSign(languageSign: string) {
    for (const country in CountryData) {
      if (get(CountryData[country], "languageSign") === languageSign) {
        return get(CountryData, [country, "language"]);
      }
    }
  }

  static getCountryCodeByLanguageSign(languageSign: string) {
    for (const country in CountryData) {
      if (get(CountryData[country], "languageSign") === languageSign) {
        return get(CountryData, [country, "countryCode"]);
      }
    }
  }

  static getCountryCodeByFullName(fullName: string) {
    for (const country in CountryData) {
      if (get(CountryData[country], "fullName") === fullName) {
        return CountryData[country].countryCode;
      }
    }
  }

  static getAllCountriesOptions() {
    return sortBy(
      Object.keys(CountryData).map(key => ({
        label: CountryData[key].fullName,
        value: CountryData[key].countryCode,
      })),
      "label",
    );
  }
}

export default CountryService;
