import { parseConstant } from "./helpers.service";

const documentTypes: { [key: string]: string } = {
  ADDITIONAL_ID: "Additional ID",
  COMPANY_FINANCIAL_STATEMENT: "Company financial statement",
  CUSTOMER_DECLARATION: "Customer Declaration",
  DRIVING_LICENCE: "Driving licence",
  EU_ID: "EU National ID card",
  EUROPEAN_ID: "European ID",
  ID_BACK_SIDE: "Reverse Side ID Scan",
  ID: "Front Side ID Scan",
  INCOME_STATEMENT: "Income statement",
  KAMER_VAN_KOOPHANDEL_UITTREKSEL: "kamer van koophandel uittreksel",
  KYC: "Know your customer",
  LOAN_AGREEMENT: "Loan agreement",
  NOTARY_DEED: "Notary Deed",
  PASSPORT: "Passport",
  PERMIT: "Residence Permit",
  PROOF_OF_ADDRESS: "Proof of Address Scan",
  PROOF_OF_NET_VALUE: "Proof of net value",
  PROOF_OF_OCCUPATION: "Proof of Occupation Scan",
  RESIDENCE_PERMIT: "Residence permit",
  SOURCE_OF_FUNDS: "Source of Funds Scan",
  UK_ID: "UK residency permit",
  WORK_PERMIT: "Work permit",
  ARTICLES_OF_ASSOCIATION: "Articles of Association",
  BILL_OF_LADEN: "Bill of laden",
  CERTIFICATE_OF_INCORPORATION: "Certificate of incorporation",
  COPY_OF_ID_FROM_LEGAL_REPRESENTATIVE: "Copy of ID from legal representative",
  ID_BACK_SIDE2: "Reverse Side ID 2",
  ID2: "ID 2",
  INDIVIDUALS_WITH_RIGHT_TO_ACT_ON_BEHALF_OF_COMPANY:
    "Letter from director confirming which named individuals have right to act on behalf of company",
  INVOICE: "Invoice",
  KNOW_YOUR_CUSTOMER_QUESTIONNAIRE: "Know Your Customer Questionnaire",
  MEMORANDUM_OF_ASSOCIATION: "Memorandum of association",
  OTHER: "Other document",
  PASSPORT_PHOTO: "Passport photo",
  POLITICALLY_EXPOSED_PERSON: "Politically Exposed Person",
  POWER_OF_ATTORNEY: "Power of attorney",
  PRO_FORMA: "Pro forma",
  PROOF_OF_ADDRESS_OF_FIRM: "Proof of address of firm",
  PUBLIC_PERSONALITY: "Public personality",
  SERVICE_APPLICANT_DECLARATION_FORM: "Service Applicant Declaration Form",
  SHAREHOLDERS_20_PERCENT_ID_AND_PROOF_OF_ADDRESS:
    "20% shareholders ID and proof of address",
  SHAREHOLDERS_20_PERCENT_PROOF_OF_ADDRESS_AND_COUNTRY_OF_RESIDENCE:
    "20% shareholders proof of address and country of residence",
  TAX_REGISTRATION_DOCUMENT: "Tax registration document",
};

const getDocumentLabel = (type: any): string => {
  if (type instanceof Object) {
    return documentTypes[type.documentType] || parseConstant(type.documentType);
  } else {
    return documentTypes[type] || parseConstant(type);
  }
};

export default {
  documentTypes,
  getDocumentLabel,
};
