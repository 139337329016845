import { AnyAction } from "redux";

import { IReducers } from "./types/create-reducer.types";

/*
  Util for making reducers without switch case
  Example of usage
  const reducerName = createReducer({
    [ActionType]: (state, { data }) => data
  }, initialValue)
*/
export const SET_DEFAULT_SATE = "SET_DEFAULT_SATE";
export const setDefaultStateAction = () => ({ type: SET_DEFAULT_SATE });

export const createReducer = <S>(reducers: IReducers<S>, initialState: S) => {
  return (state: S = initialState, action: AnyAction): S => {
    if (Reflect.has(reducers, action.type)) {
      return reducers[action.type](state, action);
    } else if (action.type === SET_DEFAULT_SATE) {
      return initialState;
    }
    return state;
  };
};
