export const appRoutes = {
  ROOT: "/",
  NOT_FOUND: "/not-found",
  SERVER_ERROR: "/server-error",
};

export const unAuthorizedRoutes = {
  HOME_PAGE: "/home",
  LOGIN_ROOT: "/login",
  LOGIN_PHONE: "/login/phone",
  LOGIN_EMAIL: "/login/email",
  LOGIN_FORGOT_PASSWORD: "/login/forgot-password",
  LOGIN_FORGOT_PASSWORD_EMAIL: "/login/forgot-password-email",
  SIGNUP_ROOT: "/signup",
  NEW_ACCOUNT: "/signup/new-account",
};

export const authorizedRoutes = {
  MONEY_TRANSFER: "/money-transfer",
  TRANSACTIONS: "/transactions",
  RECIPIENTS: "/recipients",
  CREATE_RECIPIENT: "/recipients/create",
  // EDIT_RECIPIENT: "/recipients/edit/",
  USER_PROFILE: "/user-profile",
  QUESTIONNARY: "/questionnary/:id",
  REJECTF_FLOW: "/reject-flow/",

  SETTINGS: "/settings",
  VERIFICATION: "/settings/verification",
  NOTIFICATIONS: "/settings/notifications",
  PASSWORD: "/settings/password-change",
  INVITE_FRIENDS: "/invite-friends",
  HOW_IT_WORKS: "/how-it-works",
  SUPPORT: "/support",
  PAYMENT: "/payment",
};

export const recipientsRoutes = {
  CREATE: "/create",
  EDIT: "/edit",
};

export const moneyTransferRoutes = {
  LAST_TRANSACTIONS: `${authorizedRoutes.MONEY_TRANSFER}/last-transactions`,
  AMOUNT: `${authorizedRoutes.MONEY_TRANSFER}/amount`,
  SELECT_RECIPIENT: `${authorizedRoutes.MONEY_TRANSFER}/select-recipient`,
  CONFIRMATION: `${authorizedRoutes.MONEY_TRANSFER}/confirmation`,
  EXTRA_DETAILS: `${authorizedRoutes.MONEY_TRANSFER}/extra-details`,
  PAY: `${authorizedRoutes.MONEY_TRANSFER}/pay`,
  SECURE_3D_IFRAME: `${authorizedRoutes.MONEY_TRANSFER}/3d-secure-iframe`,
  SECURE_3D: `${authorizedRoutes.MONEY_TRANSFER}/3d-secure`,
  SECURE_3D_CHECKOUT: `${authorizedRoutes.MONEY_TRANSFER}/3d-secure-checkout`,
  SECURE_3D_TRUSTPAY: `${authorizedRoutes.MONEY_TRANSFER}/3d-secure-trustpay`,
  TRANSACTION_PROCEED: `${authorizedRoutes.MONEY_TRANSFER}/transaction-proceed`,
  TRANSACTION_UNDER_REVIEW: `${authorizedRoutes.MONEY_TRANSFER}/transaction-under-review`,
  BANK_TRANSFER_SUCCESS: `${authorizedRoutes.MONEY_TRANSFER}/bank-transfer-success`,
  TRANSACTION_SUCCESS: `${authorizedRoutes.MONEY_TRANSFER}/transaction-success`,
  TRANSACTION_FAILED: `${authorizedRoutes.MONEY_TRANSFER}/transaction-failed`,
  TRANSACTION_CARD_DECLINED: `${authorizedRoutes.MONEY_TRANSFER}/transaction-card-declined`,
  TRANSACTION_CANCELLED: `${authorizedRoutes.MONEY_TRANSFER}/transaction-cancelled`,
  UPLOAD_DOCUMENTS: `${authorizedRoutes.MONEY_TRANSFER}/upload-documents`,
  COMPLIANCE_REVIEW_REQUIRE_DOC_SCREEN: `${authorizedRoutes.MONEY_TRANSFER}/documents-under-review`,
  ACCOUNT_BLOCKED: `${authorizedRoutes.MONEY_TRANSFER}/account-blocked`,
  ACCOUNT_UNDER_REVIEW: `${authorizedRoutes.MONEY_TRANSFER}/account-under-review`,
  PENDING_TRANSACTION: `${authorizedRoutes.MONEY_TRANSFER}/pending-transaction`,
  TRANSACTION_SUBMITTED: `${authorizedRoutes.MONEY_TRANSFER}/transaction-submitted`,
  REJECTF_FLOW: `${authorizedRoutes.REJECTF_FLOW}`,
};

export const API_HOST = "";
export const TOKEN_KEY = "__token";
export const EMAIL_KEY = "__email";
export const PHONE_KEY = "__phone";
export const PHONE_CODE_KEY = "__phone_key";
export const HISTORY_GO_BACK = "HISTORY_GO_BACK";
export const API_MESSAGE = "API_MESSAGE";
export const API_DATE_FORMAT = "YYYY-MM-DD";
export const FIRST_NAME = "__fname";
export const SURNAME = "__surname";
export const EMAIL = "__email_full";
