import { call, put } from "redux-saga/effects";

import { api } from "../../utils/apiClient";
import { Spinners } from "../ui/ui.constants";
import { toggleSpinner } from "../ui/ui.actions";
import environment from "../../../../environment";
import { GET_MSK_ENDPOINT } from "../transfer/transfer.constants";

export function* getMerchantSessionKeySaga() {
  try {
    yield put(toggleSpinner(Spinners.TRANSACTION_NEW_SPINNER, true));
    const {
      data: { sessionKey },
    } = yield call(api.post, GET_MSK_ENDPOINT, {
      organizationId: environment.organizationId,
    });

    return sessionKey;
  } catch (err) {
    console.log(err);
  } finally {
    yield put(toggleSpinner(Spinners.TRANSACTION_NEW_SPINNER, false));
  }
}
